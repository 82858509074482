import React, { useState } from "react";
import PropTypes from "prop-types";
import CoursesIcon from "../../icons/CoursesIcon";
import { cutString } from "../../../globalFunctions/TextModify";
import ReqDetailesModal from "./ReqDetailesModal";

export default function CurrentRequestCard({ request, regetRequestis }) {
  const [openDetailesModal, setopenDetailesModal] = useState(false);
  return (
    <>
      {openDetailesModal && (
        <ReqDetailesModal
          close={() => setopenDetailesModal(false)}
          reqId={request.id}
          req={request}
          regetRequestis={regetRequestis}
        />
      )}
      <div className="my_rounded overflow-hidden w-96 shadow-sm m-4 bg-white">
        <div className="bg-slate-600 flex justify-between p-3">
          <h4 className=" text-white">{request.student.name}</h4>
          <h4 className=" text-white">{request.student.phone}</h4>
        </div>
        <div className="p-2">
          <h5 className="my-2 ">
            طلب الاشتراك في كورس:
            <span className="text_secondary"> {request.course.name}</span>{" "}
          </h5>
          <div className="h5 flex ">
            <CoursesIcon color="#4D4D4D" />
            <div className="flex mx-2">
              <span>القسم: </span>
              <span>
                {request.course.sections.map((elem, index) => (
                  <span key={index}>
                    {elem.name}{" "}
                    {index + 1 < request.course.sections?.length && ","}{" "}
                  </span>
                ))}
              </span>
            </div>
          </div>
          <h5 className="bg-green-100 my_rounded w-full px-2 py-3 my-3 text-center ">
            السعر: {request?.price} ريال
          </h5>
          <h5 className="my-2 ">
            إثبات الدفع:
            <span className="text_secondary">
              {request?.proofOfPayment
                ? cutString(request.proofOfPayment, 25)
                : "غير موجود"}
            </span>{" "}
          </h5>
          <h5 className="my-2 ">
            ملحوظة:
            <span> {request.studentNote}</span>{" "}
          </h5>
          <button
            onClick={() => setopenDetailesModal(true)}
            className="my_btn block my-4 mx-auto px-4 py-2"
          >
            عرض التفاصيل
          </button>
        </div>
      </div>
    </>
  );
}
CurrentRequestCard.propTypes = {
  request: PropTypes.string,
  regetRequestis: PropTypes.func,
};
