import React, { useEffect, useState } from "react";
import { myFeach, parceErr } from "../globalFunctions/Api";
import PlanCard from "../components/pagesComponents/subscreptions/PlanCard";
import ResultMessage from "../components/reuseables/ResultMessage";
import PlanOverView from "../components/pagesComponents/subscreptions/PlanOverView";
import CurrentPlan from "../components/pagesComponents/subscreptions/CurrentPlan";

export default function Subscreptions() {
  const [activeFilter, setactiveFilter] = useState("شهري");
  const [plansList, setplansList] = useState({
    loader: { status: "loading" },
    list: ["", "", "", ""],
  });
  const [currententPlan, setcurrententPlan] = useState({
    loader: { status: "loading", data: {} },
  });
  const getPlans = () => {
    plansList.loader.status !== "loading" &&
      setplansList({
        ...plansList,
        loader: { status: "loading" },
        list: ["", "", "", ""],
      });
    myFeach(`plans?duration=${activeFilter}&paied=false`, "GET", {}, "token")
      .then(({ data }) => {
        if (data.plans.length) {
          setplansList({ loader: { status: "done" }, list: data.plans });
        } else {
          setplansList({
            loader: {
              status: "failed",
              text: "لا يوجد باقات في هذا التصنيف بعد",
            },
            list: data.plans,
          });
        }
        setcurrententPlan({
          loader: { status: "done" },
          data: data.currentPlan,
        });
      })
      .catch((err) => {
        let textErr = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل الباقات";
        setplansList({ loader: { status: "failed", text: textErr } });
        setcurrententPlan({
          loader: { status: "failed", text: "فشل تحميل الباقة الحالية" },
        });
      });
  };

  useEffect(() => {
    getPlans();
  }, [activeFilter]);

  return (
    <main className="">
      <PlanOverView />
      <CurrentPlan currententPlan={currententPlan} />
      <div className="flex justify-between w-full py-8 border-t-2 flex-wrap ">
        <h3 className="font-bold md:mb-0 mb-4">
          تواصل معنا للاستفسار عن كل ما يخص باقاتنا الأخرى
        </h3>
        <a
          href="https://wa.me/+201278279346"
          rel="noreferrer"
          target="_blank"
          className="gradient_border px-4 py-1 h-fit "
        >
          تواصل معنا
        </a>
      </div>
      <div className="flex flex-wrap  m-auto my_rounded overflow-hidden bg-white shadow-sm md:p-0 p-3 w-full  ">
        {filters.map((filter, index) => (
          <button
            disabled={plansList.loader.status === "loading" ? true : false}
            key={index}
            onClick={() => setactiveFilter(filter.key)}
            className={`${activeFilter === filter.key ? "bg_secondary" : ""} px-6 py-2 w-1/2  md:w-1/4`}
          >
            {filter.title}
          </button>
        ))}
      </div>
      {plansList.loader.status === "failed" ? (
        <div className="mb-32">
          <ResultMessage message={plansList.loader.text} />
        </div>
      ) : (
        <div className="flex flex-wrap mt-10">
          {plansList.list.map((plan, index) => (
            <PlanCard
              duration={activeFilter}
              cardData={plan}
              key={index}
              loader={plansList.loader.status}
            />
          ))}
        </div>
      )}
    </main>
  );
}
const filters = [
  { title: "اشتراك شهري", key: "شهري" },
  { title: "اشتراك 3 شهور", key: "3 شهور" },
  { title: "اشتراك 6 شهور", key: "6 شهور" },
  { title: "اشتراك سنوي", key: "سنوي" },
];
