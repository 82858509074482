import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { myFeach, parceErr } from "../../globalFunctions/Api";
export default function FilterListWithSearch({
  apiPath,
  onselect,
  resultKey,
  width,
}) {
  const listRef = useRef(null);
  const inputRef = useRef(null);
  const [list, setlist] = useState({
    loader: { status: "defult" },
    liat: [],
  });
  const [inputIsFocused, setinputIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeChoise, setactiveChoise] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  //   handle get list of choises
  const handleGetList = (searchValue) => {
    myFeach(
      `${apiPath}${searchValue && `&searchKey=${searchValue}`}`,
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        if (data.students.length) {
          setlist({ list: data[resultKey], loader: { status: "done" } });
        } else {
          setlist({
            list: [],
            loader: { status: "failed", text: "لا يوجد نتائج لعرضها" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        let textErr = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل القائمة";
        setlist({ loader: { status: "failed", text: textErr }, list: [] });
      });
  };

  //   handle get list of student if user stop entering data to 1 seconds by call handle get list fun
  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (activeChoise) {
      // handle if getting new list delete the current choise
      setactiveChoise("");
      handleSelect("", "", "clearingInput");
    }
    setlist({ ...list, loader: { status: "loading" } });
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      if (value) {
        handleGetList(value);
      } else {
        setlist({
          list: [],
          loader: { status: "defult" },
        });
      }
    }, 1000);
    setTimeoutId(newTimeoutId);
  };
  //   handle select choice
  const handleSelect = (id, name, type) => {
    if (type != "clearingInput") {
      setInputValue(name);
    }
    setactiveChoise(id);
    onselect(id);
  };
  //   handle open and close list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        listRef.current.contains(event.target) ||
        inputRef.current.contains(event.target)
      ) {
        inputIsFocused != true && setinputIsFocused(true);
      } else {
        setinputIsFocused(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className=" w-fit relative">
      <input
        style={{ width: width }}
        onChange={handleChange}
        value={inputValue}
        ref={inputRef}
        placeholder="أدخل اسم الطالب أو رقم هاتفه"
        className="text-sm"
      />
      <ul
        ref={listRef}
        style={{ width: width }}
        className={` ${!inputIsFocused ? "display_none" : ""} mt-1 my_border my_rounded bg-white absolute top-11 `}
      >
        {list.loader.status == "loading" && (
          <div className="flex items-center h-32 justify-center ">
            <div className="loader"></div>
          </div>
        )}
        {list.loader.status == "failed" && (
          <div className="h-32 flex items-center justify-center">
            <h6 className="text-red-400">{list.loader.text}</h6>
          </div>
        )}
        {list.loader.status == "defult" && (
          <div className="h-32 flex items-center justify-center">
            <h6 className="text-center p-1">
              قم بإدخال اسم الطالب أو رقم هاتفه
            </h6>
          </div>
        )}
        {list.loader.status == "done" && (
          <div className="min-h-32 p-1">
            {/* <li
              className={` pointer h-7 botom_border  hover:bg-blue-500 hover:text-white px-1 `}
              onClick={() => {
                handleSelect("", "");
                // setlist({
                //   list: [],
                //   loader: { status: "defult" },
                // });
              }}
            >
              {" "}
            </li> */}
            {list.list.map((elem, index) => (
              <li
                onClick={() => handleSelect(elem.id, elem.name)}
                className={`${activeChoise == elem.id ? "bg-blue-500 text-white" : ""} pointer hover:bg-blue-500 hover:text-white px-1 botom_border py-1 `}
                key={index}
              >
                {elem.name}
              </li>
            ))}
          </div>
        )}
      </ul>
    </div>
  );
}
FilterListWithSearch.propTypes = {
  apiPath: PropTypes.string,
  resultKey: PropTypes.string,
  width: PropTypes.string,
  onselect: PropTypes.func,
};
